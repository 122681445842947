.cursor-grab {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.pointer {
  cursor: pointer;
}

.password-display{
  position: absolute;
  margin-top: 7px;
  margin-left: -25px;
}

