.badge {
  color: $static-white;
}

@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
    @include badge-variant(rgba($value, 0.175));
    color: $value;
  }
}

.badge.rounded-pill {
  padding-right: .65em;
  padding-left: .65em;
}

.badge-primary {
  color: #232c73;
  font-weight: 600;
  background-color: #e6f6fe;
}

.badge-primary:hover {
  background-color: #cfdde5;
  cursor: pointer;
}

.action-link.secondary:hover {
  color: #366dc7!important;
  background-color: #f4f8fb;
}
