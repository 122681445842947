.btn-link:not(:hover) {
  text-decoration: none;
}

.btn-pill {
  border-radius: 10rem;
}

.switch-btn{
  top: -4px!important;
  position: relative;
}

.btn-square {
  border-radius: 0;
}

.btn .feather {
  width: 14px;
  height: 14px;
}

.btn-lg .feather {
  width: 15px;
  height: 15px;
}

.delete-btn-container{
  max-width: 163px;
}

.delete-btn{
  height: 35px;
  width: 35px;
  border-radius: 100%;
}

.btn-delete{
  border-radius: 20px;
}

.file-inner {
  border-radius: 10px;
  border: 2px solid #3F80EA;
  color: #3F80EA;
  height: 100%;
}


.upload-btn{
  height: 32px!important;
  width: 110px!important;
  font-size: 13px!important;
  background-color: transparent!important;
  color: $blue!important;
  border: 0 white!important;
  :hover{
	text-decoration: underline;
  }
}

.action-link{
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 5px;
  white-space: nowrap;
}

.confirm-container {
  border: 1px solid;
  width: 30px;
  height: 30px;
  padding: 4px 0px;
  line-height: 23px;
  border-radius: 100%;
  cursor: pointer;
}

.page-item {
  &:first-child {
	.page-link {
	  margin-left: 0;
	  @include border-radius(100%);
	}
  }

  &:last-child {
	.page-link {
	  @include border-radius(100%);
	}
  }
}

.page-item.active{
  background-color: transparent !important;
  a{
	color: white;
  }
}
.page-item, .page-item.disabled {
  .page-link {
	color: $primary;
	background-color: transparent;
	border: none;
	border-radius: 50%;
	height: 35px;
	width: 35px;
	padding: initial;
	line-height: 35px;
	text-align: center;
	margin: 0 3px;
	cursor: pointer;

	&:hover {
	  color: white;
	  background-color: $primary;
	}
  }

  &.active {
	background-color: $secondary;

	.page-link {
	  background-color: $primary;
	  border-radius: 50%;
	}
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-color($static-white);
    @include button-color-hover($static-white);
    @include button-color-active($static-white);
  }

  .btn-outline-#{$color} {
    @include button-color-hover($static-white);
    @include button-color-active($static-white);
  }
}

@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    @include button-color($static-white);
    @include button-color-hover($static-white);
  }
}

.btn-light,
.btn-white {
  @include button-color($gray-800);
  @include button-color-hover($gray-800);
}

.btn-outline-light,
.btn-outline-white {
  @include button-color-hover($gray-800);
  @include button-color-active($gray-800);
}
