.overflow-scroll {
    overflow: scroll;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-hidden{
  	overflow-x: hidden;
}

.overflow-y-hidden{
  overflow-y: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflow-visible {
    overflow: visible;
}

.overflow-y-scroll{
  overflow-y: scroll;
}
