.absolute-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.float-right{
  float: right;
}

.text-right{
  text-align: right;
}

.position-initial{
  position: initial!important;
}

.flex-direction-inherit{
  flex-direction: inherit;
}
