.fs-lg {
  font-size: $font-size-lg!important;
}

.fs-sm {
  font-size: $font-size-sm;
}

.no-wrap{
  white-space: nowrap;
  word-wrap: break-word;
}

.gray-txt{
  color: #555555!important;
}
