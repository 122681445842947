@import "../scss/colors";

.modal-header {
	background-color: $sidebar;
    h4{
        color: white;
    }
}

.table-menu {
    background: $white;
    border-bottom: 1px solid $light-gray-shade-2;
    padding-top: 8px;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 1rem;
}

.table-menu .nav-link {
    color: #495057;
    background-color: #fff;
    border-radius: unset;
    border: none;
    height: 100%;
    border-bottom: 3px solid #edf1f5;
    display: block;
    padding: 0.5rem 1rem;

    &.active {
        border-bottom: 3px solid $primary-blue;
        border-radius: unset;
    }

    &:focus {
        outline: unset;
    }
}

@media(max-width: 768px) {
    .menu-icon-box {
        span {
            font-size: 12px;
        }

        i {
            display: block;
        }

        div {
            line-height: 1.3;
            margin-top: 7px;
            font-size: 13px;
        }
    }
}
