// Variables
@import "assets/scss/1-variables/app";

// Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

// Theme mixins
@import "assets/scss/2-mixins/background";
@import "assets/scss/2-mixins/badge";
@import "assets/scss/2-mixins/button";
@import "assets/scss/2-mixins/modal";
@import "assets/scss/2-mixins/switch";
@import "assets/scss/2-mixins/tabs";
@import "assets/scss/2-mixins/theme";
@import "assets/scss/2-mixins/wizard";

//MAIN CSS
@import "assets/scss/typography";
@import "assets/scss/base";

// Theme components
@import "assets/scss/3-components/accordion";
@import "assets/scss/3-components/alert";
@import "assets/scss/3-components/avatar";
@import "assets/scss/3-components/badge";
@import "assets/scss/3-components/buttons";
@import "assets/scss/3-components/card";
@import "assets/scss/3-components/chart";
@import "assets/scss/3-components/chat";
@import "assets/scss/3-components/content";
@import "assets/scss/3-components/dropdown";
@import "assets/scss/3-components/feather";
@import "assets/scss/3-components/footer";
@import "assets/scss/3-components/forms";
@import "assets/scss/3-components/hamburger";
@import "assets/scss/3-components/illustrations";
@import "assets/scss/3-components/landing";
@import "assets/scss/3-components/main";
@import "assets/scss/3-components/modal";
@import "assets/scss/3-components/navbar";
@import "assets/scss/3-components/progress";
@import "assets/scss/3-components/reboot";
@import "assets/scss/3-components/root";
@import "assets/scss/3-components/settings";
@import "assets/scss/3-components/sidebar";
@import "assets/scss/3-components/sizing";
@import "assets/scss/3-components/stats";
@import "assets/scss/3-components/tables";
@import "assets/scss/3-components/tabs";
@import "assets/scss/3-components/timeline";
@import "assets/scss/3-components/type";
@import "assets/scss/3-components/wizard";
@import "assets/scss/3-components/wrapper";

// Theme utilities
@import "assets/scss/4-utilities/background";
@import "assets/scss/4-utilities/borders";
@import "assets/scss/4-utilities/cursors";
@import "assets/scss/4-utilities/font";
@import "assets/scss/4-utilities/overflow";
@import "assets/scss/4-utilities/position";
@import "assets/scss/4-utilities/z-index";

@import '~ngx-toastr/toastr';
@import '../node_modules/ngx-ui-switch/ui-switch.component.css';


