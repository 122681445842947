body, html, #root {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  opacity: 1 !important;
}

div[data-sidebar-position=right] {
  direction: rtl;
}

.slbQA{
  .slbWrapOuter, .slbOverlay{
	width: 50%;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow-x: hidden;
  }
}
