$secondary: #ddd;
$primary: #54667a;
$primary-blue: #232c73;
$complementary: #005DAE; //#2C6990
$white: #ffffff;
$sidebar: #293042;

//Light Palette
$light-gray-shade-2: #edf1f5;
$light-blue-shade-2: #f4f8fb;
$light-blue-shade-3: #f3f7fd;
$light-blue-shade-1: #2c3790;

$light-red-shade-1: #ff6c60;
$light-red-shade-2: #ff1c25;

$light-green-shade-1: #2ecc71;

//Dark palette
$dark-gray-shade-1: #34495f;
$dark-gray-shade-2: rgb(136, 136, 136, 0.3);
$dark-red-shade-1: #c82333;
$dark-blue-shade-1: #1a2156;

//Gradients
$green-to-blue: linear-gradient(45deg, #00a8f4 0%, #02d1a1 100%);

$accent-success: #82f181;

/**STATUS PALETTE*/
$status-1: #2b76e5;
$status-2: #225091;
$status-3: #175a63;
$status-4: #9cd326;
$status-5: #cab641;
$status-6: #fdab3d;
$status-7: #579bfc;
$status-8: #0086c0;
$status-9: #ff642e;
$status-10: #ff7575;
$status-11: #e2445c;
$status-12: #333333;
$status-13: #ad967a;
$status-14: #ffadad;
$status-15: #4eccc6;
$status-16: #9aadbd;
$status-17: #5559df;
$status-18: #ffcb00;
$status-19: #7e3b8a;
$status-20: #bb3354;
$status-21: #00c875;
