@each $color, $value in $custom-colors {
  @include bg-variant(".bg-#{$color}", $value, true);
}

.images, .files{
  object-fit: cover;
  height: 163px!important;
  width: 163px!important;
  border-radius: 10px;
}

.file-upload-container{
  object-fit: cover;
  height: 163px!important;
  width: 163px!important;
  border-radius: 10px;
  color: #3F80EA!important;
  border-color: #3F80EA!important;
}

@media (max-width: 576px) {
  .images, .files, .file-upload-container{
	height: 250px!important;
	width: 250px!important;
	margin-top: 20px;
  }

  .images-center-xs{
	text-align: center;
  }
}
