@import "./colors";

p, h1, h2, h3, h4, h5 {
    font-weight: 400;
    color: $dark-gray-shade-1;
}

.card-header {
    i {
        font-size: 1.7rem;
    }
}

.title-higlight {
    color: $complementary;
    font-weight: 700;
}

.text-comp {
    color: $complementary !important;
}


.gray-txt {
    color: $dark-gray-shade-1 !important;
}

.light-gray {
    color: $light-blue-shade-2;
}
